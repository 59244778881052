<template>
  <div></div>
</template>
<script>
export default {
  name:'jump',
  created(){
    let query = {...this.$route.query};
    let path = this.$route.query.path.replace('/','');
    console.log(path,"-------------------------------",this.$route.query.path)
    let params = "";
    if(this.$config.branch){
      params = `route||${this.$config.branch}||`
    }
    params += `path||${path == '/' ? '' : path}`;
    for(let i in query){
      if(i=='path') continue;
      params += `||${i}||${query[i]}`
    }
    window.location.href= `${this.$urls.jopenid}?params=${params}`
  },
}
</script>